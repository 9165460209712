import { Store } from "@retailtune/types/lib/store";

export function selectStoreIcon(store: Store, urlBase: string) {
  for (let i = 0; i < store.storeTypeLabels.length; ++i) {
    switch (store.storeTypeLabels[i]) {
      case "OFFICIALRETAILER":
        return `${urlBase}/img/pin/pin-corner.svg`;
      case "STORE":
        return `${urlBase}/img/pin/pin-studio.svg`;
      case "FLAGSHIPSTORE":
        return `${urlBase}/img/pin/pin-flagship.svg`;
      default:
        return `${urlBase}/img/pin/pin-studio.svg`;
    }
  }
  // in case no type label is found
  return `${urlBase}/img/pin/pin-studio.svg`;
}

export const isInViewport = (elem: HTMLElement) => {
  const rect = elem.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};
